@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
	background-color: #e1dfdd;
	font-family: "Poppins", sans-serif;
}

.container {
	width: 100%;
	height: 100%;
}
.navbar {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 25px;
	padding: 50px;
	padding-bottom: 20px;
	padding-top: 25px;
	font-size: 20px;
}
.navbar a {
	text-decoration: none;
	color: #4e4e4e;
}
.navbar a:hover {
	cursor: pointer;
	text-decoration: overline;
}
.navbar-name {
	grid-column-start: 1;
}
.navbar-about {
	grid-column-start: 11;
}
.navbar-contact {
	grid-column-start: 12;
}
.summary-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 25px;
	max-width: 100%;
	margin-top: 0;
	padding: 50px;
	padding-top: 0px;
	padding-bottom: 100px;
}

.title {
	grid-column-start: 1;
	grid-column-end: 6;
	color: black;
	line-height: 100px;
}

.title p {
	font-size: calc(50px + 5vw);
	margin: 0;
	margin-top: 20px;
}

.name {
	grid-column-start: 7;
	grid-column-end: 13;
	grid-row-start: 2;
	text-align: right;
	font-size: calc(50px + 5vw);
	color: black;
	line-height: 100px;
	margin: 0;
}

.summary {
	grid-column-start: 4;
	grid-column-end: 7;
	grid-row-start: 4;
	font-size: calc(14px + 1vw);
	text-align: left;
}

.tech-container {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(3, 1fr);
	margin-top: 100px;
}

.tech-title {
	grid-column-start: 1;
	grid-column-end: 12;
	grid-row-start: 1;
}

.tech-title h3 {
	font-size: 60px;
	text-align: right;
	color: rgb(120, 120, 120);
	padding-right: 100px;
	text-decoration: underline;
	text-decoration-thickness: 4px;
}

.tech-list {
	grid-column-start: 1;
	text-align: center;
	grid-column-end: 9;
	grid-row-start: 2;
	grid-row-end: 4;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-gap: 0px;
}

.tech-list ul {
	font-size: 30px;
}

.project-card {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 25px;
	margin: 100px 50px 190px 50px;
}

.project-card img {
	grid-column-end: 13;
	grid-row-end: span 3;
	width: 700px;

}

.project-text {
	grid-column-start: 1;
	grid-column-end: 5;
	grid-row-start: 1;
}
.project-text h1 {
	color: black;
	font-weight: 100;
	font-size: calc(30px + 5vw);
	margin: 0px;
	text-decoration: underline;
	text-decoration-thickness: 5px;
	line-height: 90px;
}

.project-text h4 {
	font-size: calc(8px + 1vw);
	text-align: left;
	line-height: 30px;
	margin: 68px 0px 120px 98px;
}
.project-tech {
	grid-column-start: 1;
	grid-column-end: 8;
	margin-top: auto;
}

.project-tech ul {
	font-size: 20px;
	float: left;
}
.project-tech img {
	width: 40px;
	height: 50px;

}

.project-links {
	grid-row-start: 3;
}
.project-links a {
	text-decoration: none;
	color: rgb(119, 119, 119);
	font-size: 20px;
	padding-left: 40px;
}
.project-links a:hover {
	cursor: pointer;
	text-decoration: underline;
}

/* ABOUT */

.about-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	max-width: 100%;
	justify-items: center;
	margin-top: 70px;
}
.about-img {
	grid-column-start: 3;
}
.about-img img {
	width: 500px;
	border-radius: 50%;
}
.about-links {
	grid-column-start: 3;
}
.about-links ul {
	display: inline-block;
	list-style: none;
	padding: 10px;
}
.about-links a {
	text-decoration: none;
	color: #000000;
	font-size: 23px;
}
.about-links a:hover {
	cursor: pointer;
	text-decoration: underline;
}

.about-text h2 {
	font-size: calc(40px + 2vw);
	text-align: left;
	margin-top: 0px;
	margin-bottom: 0px;
}

.about-text h3 {
	font-size: calc(7px + 1vw);
	text-align: left;
	font-weight: 100;
	color: #4e4e4e;
	margin: 0px;
}
.about-text {
	grid-column-start: 5;
	grid-column-end: 12;
	padding-right: 50px;
	max-width: 600px;
}

.about-text p {
	margin-top: 25px;
	font-size: calc(4px + 1vw);
	font-weight: lighter;
}
.contact-container {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: 10px;
	padding: 150px 0 200px;
	text-align: center;
}

.contact-title {
	font-size: 25px;
	grid-column-start: 1;
	grid-column-end: 7;
}
.contact-email {
	grid-column-start: 1;
	grid-column-end: 7;
	font-size: calc(40px + 4vw);
	line-height: 0.875;
	color: #000000;
}
.contact-linkedin {
	margin-top: 50px;
	grid-column-start: 1;
	grid-column-end: 7;
	color: #000000;
	font-size: calc(40px + 4vw);
}
